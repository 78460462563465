.password-strength-meter {
  margin-top: -25px;
  width: 100%;
  /* bottom:-.1em;
    width: 100%;
    height: 1.25em;
    text-align: left; */
  /* @media (max-width: 1400px) {
      bottom:.1em;
    }
    @media (max-width: 1400px) {
      bottom:.2em;
    }
    @media (max-width: 1200px) {
      bottom:-.1em;
    } */
}

.password-strength-meter-progress {
  background: #fff;
  width: 100%;
  height: 0.3125em;
  /* margin: 0 2px; */
  border: 0;
}


.password-strength-meter-progress::-webkit-progress-bar {
  background: #fff;
  /* border: 1px solid #a4a1a6; */
  background-size: contain;
  border-radius: 0;
}

.password-strength-meter-label {
  top: -90%;
  left: calc(100% + 0.75em);
  padding: 4px 15px !important;
  font-size: 0.875em !important;
  width: auto;
  text-align: center;
  display: inline-block;
  background: transparent;
  line-height: 1.21;
  border-radius: 4px;
  color: #fff !important;
  @media (max-width: 767px) {
    top: -280%;
    left: auto;
    right: 0;
  }
}

.password-strength-meter-label::before {
  @media (min-width: 767px) {
    content: "";
  }
  position: absolute;
  bottom: 35%;
  left: -4px;
  transform: translate(-50%, 0) rotate(-90deg);
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 8px solid transparent;
}
.strength-Weak ~ .password-strength-meter-label::before {
  border-bottom-color: #fd7373;
}

.strength-Fair ~ .password-strength-meter-label::before {
  border-bottom-color: #ffcc00;
}

.strength-Good ~ .password-strength-meter-label::before {
  border-bottom-color: #13d1af;
}

.strength-Excellent ~ .password-strength-meter-label::before {
  border-bottom-color: #13d16c;
}

.strength-Weak ~ .password-strength-meter-label {
  background: #fd7373;
}
.strength-Fair ~ .password-strength-meter-label {
  background: #ffcc00;
}
.strength-Good ~ .password-strength-meter-label {
  background: #13d1af;
}
.strength-Excellent ~ .password-strength-meter-label {
  background: #13d16c;
}

.password-strength-meter-progress::-webkit-progress-value,
.password-strength-meter-progress::-moz-progress-bar {
  border-radius: 2px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.strength-Weak::-moz-progress-bar {
  background-color: #fd7373;
}
.strength-Fair::-moz-progress-bar {
  background-color: #ffcc00;
}
.strength-Good::-moz-progress-bar {
  background-color: #13d1af;
}
.strength-Excellent::-moz-progress-bar {
  background-color: #13d16c;
}

.strength-Weak::-webkit-progress-value {
  background-color: #fd7373;
}
.strength-Fair::-webkit-progress-value {
  background-color: #ffcc00;
}
.strength-Good::-webkit-progress-value {
  background-color: #13d1af;
}
.strength-Excellent::-webkit-progress-value {
  background-color: #13d16c;
}
